<template>
  <b-card bg-variant="Default" title="tabella con paginazione e caricamento dati per 'singola pagina' (molto veloce)">
    <div class="demo-inline-spacing pb-3">
        <b-button
        variant="primary"
        :to="{ name: 'demo-mio-pagina-vuota'}"
        >
            <feather-icon
                icon="DatabaseIcon"
                size="16"
                class="mr-1"
            />Tabella con singolo caricamento
        </b-button>
        <b-button
        variant="success"
        :to="{ name: 'demo-mio-formnuovo-test1'}"
        >
            <feather-icon
                icon="PlusIcon"
                size="16"
                class="mr-1"
            />Nuovo
        </b-button>
    </div>

    <!-- table -->
    <vue-good-table
    mode="remote"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
    @on-per-page-change="onPerPageChange"
    :totalRows="totalRecords"
    :rows="rows"
    :columns="columns"
    :isLoading.sync="isLoading"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    :pagination-options="{
        enabled: true,
        mode: 'pages',
        nextLabel: 'successiva',
        prevLabel: 'precedente',
        rowsPerPageLabel: 'righe per pagina',
        ofLabel: 'di',
        pageLabel: 'pagina', // for 'pages' mode
        perPage: 10
    }"
    styleClass="vgt-table">

      <template slot="loadingContent_stop">
        <div class="bg-primary"> animated loading ....</div>
      </template>

      <div slot="emptystate"> <div class="text-center"> Nessuna dato disponibile </div> </div>

      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mr-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </div>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <div class="text-nowrap">
            <feather-icon
                :id="`tabellatest1-riga-${props.row.id}-modifica`"
                icon="EditIcon"
                class="cursor-pointer mr-1"
                size="21"
                @click="$router.push({ name: 'demo-mio-formedit-test1', params: { id: props.row.id }})"
            />
            <b-tooltip
                title="Modifica"
                class="cursor-pointer"
                :target="`tabellatest1-riga-${props.row.id}-modifica`"
            />

            <feather-icon
                :id="`tabellatest1-riga-${props.row.id}-preview`"
                icon="InfoIcon"
                class="cursor-pointer mr-1"
                size="21"
                @click="apriPreview(props.row.id)"
            />
            <b-tooltip
                title="preview"
                class="cursor-pointer"
                :target="`tabellatest1-riga-${props.row.id}-preview`"
            />
            
            <feather-icon
                :id="`tabellatest1-riga-${props.row.id}-elimina`"
                icon="XCircleIcon"
                class="cursor-pointer mr-1"
                size="21"
                @click="cancellaRiga(props.row.id)"
            />
            <b-tooltip
                title="cancella"
                class="cursor-pointer"
                :target="`tabellatest1-riga-${props.row.id}-elimina`"
            />

            <b-dropdown
                variant="link"
                toggle-class="p-0"
                no-caret
                :right="$store.state.appConfig.isRTL"
            >

                <template #button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                />
                </template>
                <b-dropdown-item>
                <feather-icon icon="DownloadIcon" />
                <span class="align-middle ml-50">Download</span>
                </b-dropdown-item>
                <b-dropdown-item :to="{ name: 'apps-invoice-edit', params: { id: props.row.id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item>
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
                <b-dropdown-item>
                <feather-icon icon="CopyIcon" />
                <span class="align-middle ml-50">Duplicate</span>
                </b-dropdown-item>
            </b-dropdown>
          </div>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      
    </vue-good-table>

    <b-modal ref="my-modal" hide-footer centered title="Informazioni Utente" modal-class="modal-primary" size="lg">
      <div class="d-block text-center">
        <b-row class="pb-2 text-left">
            <b-col md="4" lg="3">Nome e Cognome</b-col>
            <b-col md="8" lg="9">{{ infomodal.nomeecognome }}</b-col>
        </b-row>
        <b-row class="pb-2 text-left">
            <b-col md="4" lg="3">Email</b-col>
            <b-col md="8" lg="9">{{ infomodal.emailValue }}</b-col>
        </b-row>
        <b-row class="pb-2 text-left">
            <b-col md="4" lg="3">Date </b-col>
            <b-col md="8" lg="9">{{ infomodal.startdata }}</b-col>
        </b-row>
        <b-row class="pb-2 text-left">
            <b-col md="4" lg="3">Salario</b-col>
            <b-col md="8" lg="9">{{ infomodal.salario }}</b-col>
        </b-row>
        <b-row class="pb-2 text-left">
            <b-col md="4" lg="3">Ruolo (Status)</b-col>
            <b-col md="8" lg="9">{{ infomodal.selected }}</b-col>
        </b-row>
      </div>
    </b-modal>

  </b-card>

</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { BCard, BAvatar, BBadge, BFormGroup, BFormInput, BFormSelect, BButton, BDropdown, BDropdownItem, BTooltip, BModal, VBModal } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'

export default {
  components: {
    BRow,
    BCol,

    BCard,
    VueGoodTable,
    BButton,
    BTooltip,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
  },
  directives: {
    'b-modal': VBModal
  },
  data() {
    return {
      isLoading: true,
      emptystate: true,
      pageLength: 10,
      columns: [
        {
          label: 'Nome e Cognome',
          field: 'fullName',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name',
          },
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Email',
          },
        },
        {
          label: 'Date',
          field: 'startDate',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Date',
          },
        },
        {
          label: 'Salario',
          field: 'salary',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Salary',
          },
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Status',
          },
        },
        {
          label: 'Operazioni',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      totalRecords: 0,
      serverParams: {
        columnFilters: {
        },
        sort: [
            {
            field: '',
            type: ''
            }
        ],
        page: 1, 
        perPage: 10,
        licenza_pubblica: 'testlicenza'
      },
      infomodal: {
        emailValue: '',
        nomeecognome: '',
        startdata: '',
        salario: '',
        selected: '',
      },
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        CTO      : 'light-primary',
        professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    console.log('created ....................')

    //this.rows = [];
    this.loadItems();

    //this.$http.get('/good-table/basic')

    /*
    this.$http.get('demo/lista_tabella_json_v3.php')
        .then(res => { 
            //console.log("risposta ???"); 
            //console.log(res.data.dati);

            this.rows = res.data.dati
        })
        */
    
  },
  methods: {
    updateParams(newProps) {
      //console.log("updateParams --- vvv ")
      console.log(newProps)
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage});
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [{
          type: params.sortType,
          field: this.columns[params.columnIndex].field,
        }],
      });
      this.loadItems();
    },
    
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    // load items is what brings back the rows from server
    loadItems() {
        
        console.log("loadItems ......");
        //console.dir(this.serverParams)
        //this.isLoading = true;

        //this.updateParams({token_user: "fanco-maria-123"})
        
        //this.$http.get('demo/lista_tabella_json_v3.php', {
        this.$http.get('v2/democrud/', {
            params: this.serverParams 
            }).then(response => { 
                /*
                console.log("risposta vvv"); 
                console.log(response.data)
                console.log('--- ---')
                console.dir(response.data)
                console.log("response.totalRecords -> "+response.data.totalRecords);
                */

                //console.log(response.data.rows)

                
                this.totalRecords = response.data[0].contarighe;
                this.rows = response.data[1].righe;
                
                //rimuovi il loading dopo il caricamento dei dati nella tabella
                this.isLoading = false;

                /*
                this.totalRecords = response.data.totalRecords;
                this.rows = [
                    {
                    id: 1,
                    avatar: '/img/10-small.2b24b197.png',
                    fullName: "Pippo",
                    email: 'kocrevy0@thetimes.co.uk',
                    startDate: '09/23/2016',
                    salary: '$23896.35',
                    status: 'Professional',
                    },
                    {
                    id: 2,
                    avatar: '/img/10-small.2b24b197.png',
                    fullName: 'Pluto',
                    email: 'bcoulman1@yolasite.com',
                    startDate: '05/20/2018',
                    salary: '$13633.69',
                    status: 'Resigned',
                    },
                ];
                */
            
            })  
            
        
      /*  
      getFromServer(this.serverParams).then(response => {
         this.totalRecords = response.totalRecords;
         this.rows = response.rows;
      });
      */
    },

    apriPreview(id){
      console.log("click apriPreview ...... id -> "+id);

      this.$http.get('v2/democrud/singolo/'+id)
        .then(response => { 
            console.log("risposta server --------")
            console.log(response.data)

            if(response.data.statusCode===200){
            //risposta positiva
                this.infomodal = { emailValue: response.data.dati.emailValue,
                nomeecognome: response.data.dati.nomeecognome,
                startdata: response.data.dati.startdata,
                salario: response.data.dati.salario,
                selected: response.data.dati.selected, };

                this.$refs['my-modal'].show()
            } else {
            //risposta negativa (errore sul server)
            
            }
        }).catch(e => {
            console.log(e);
        });
    },
    cancellaRiga(id){
      console.log("click cancellaRiga ...... id -> "+id);

      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: "conferma la richiesta di cancellazione",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'SI, Cancella',
        cancellButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

            this.$http.get('v2/democrud/cancellasingola/'+id)
            .then(response => { 
                console.log("risposta server --------")
                console.log(response.data)
                console.log("response.data.dati.id_cancellato -> "+response.data.dati.id_cancellato)

                if((response.data.statusCode===200) && (response.data.dati.id_cancellato == id)){
                //risposta positiva
                    this.$swal({
                        icon: 'success',
                        title: 'Cancellato!',
                        text: 'operazione effettuata correttamente',
                        confirmButtonText: 'chiudi',
                        customClass: {
                        confirmButton: 'btn btn-success',
                        },
                    })

                    //aggiorna tabella
                    this.loadItems();
                    
                } else {
                //risposta negativa (errore sul server)
                    this.$swal({
                        icon: 'error',
                        title: 'Si è verificato un errore',
                        text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema - ERRORE DS-014',
                        customClass: {
                        confirmButton: 'btn btn-outline-primary',
                        },
                    })
                }
            }).catch(e => {
                console.log(e);
            })
        }
      })
    }
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
